import React from "react";
import { URL_DB, NOMBRE_SIST } from "../../constants";
import axios from "axios";
import {
    Table,
    Row,
    Col,
    Container,
    Button,
    Form,
    FormControl,
    Modal,
    InputGroup
} from "react-bootstrap";
import swal from "sweetalert";
import { FaPlay, FaDownload, FaTrashAlt, FaSistrix } from "react-icons/fa";
import Notifications from "../../components/Notifications";
import ordenarGrilla from "../../utils/functions/ordenar-grilla";
import fileSaver from "file-saver";
//import StructuredFilter from 'react-structured-filter';
import Select from "react-select";
import ReactPlayer from "react-player";
import exportToCSV from "../../utils/functions/export-excel";
import "../stylePages.css";
//import { Howl } from 'howler'

class LlamadasPage_v2 extends React.Component {
    constructor(props) {
        super(props);

        var hoyd = new Date();
        var ayer = new Date();
        ayer.setDate(ayer.getDate() - 2);

        this.state = {
            desde: ayer.toISOString().split("T")[0] + "T00:00",
            hasta: hoyd.toISOString().split("T")[0] + "T23:59",
            txt_en_msg: "",
            registrosOrig: [],
            registros: [],
            listaCol: [],
            // listaStatus: [],
            buscarGrillaValue: "",
            showFiltro: false,

            labelFiltro: "Más Filtros",

            filtroGridSelect: [],
            filtroGridElem: [],
            filtroGridRenglon: [],
            filtroGridQuery: "",
            filtroGridQuerySw: "",
            filtroGridShow: false,
            filtroGridCol: "",
            filtroGridLabel: "",
            filtroGridTipo: "",

            login_usua_id: sessionStorage.getItem("USUARIO"),

            playingMp3: false,

            showModalPlay: false,
            showModalDownAudio: false,
            fecha: "",
            callid: "",
            origen: "",
            destino: "",
            escuchar: "",
            showhelp: false,
            filterGrilla: "",
            fetchRegistros: false,
            respuestaSp: [],
            respError: "",
            mensajeAlerta: "",
            usua_nivel: ""
        };
        this.ordenarGrilla = this.ordenarGrilla.bind(this);
        this.poblarGrilla = this.poblarGrilla.bind(this);
        this.handleChangeOpc = this.handleChangeOpc.bind(this);
        //this.handlefiltroGridAgregar = this.handlefiltroGridAgregar.bind(this);
    }

    async getColumnas() {
        const sql = `${URL_DB}SEL_LLAMADAS_RS('T',0,null,null)`;
        axios
            .get(sql)
            .then(response => {
                let filtroGridElem = response.data[0].map(item => {
                    var obj = {};
                    obj.value = item.col;
                    obj.label = item.nombre;
                    obj.tipo = item.tipo;
                    obj.miembro_in = "";
                    obj.miembro_sh = "";
                    return obj;
                });
                this.setState({
                    filtroGridElem
                });
            })
            .catch(error => console.log(error));
    }

    async getUsuario() {
        const sql = `${URL_DB}SEL_USUARIO(${this.state.login_usua_id})`;
        axios
            .get(sql)
            .then(response => {
                this.setState({ usua_nivel: response.data[0][0].usua_nivel });
            })
            .catch(error => console.log(error));
    }

    async getInicio() {
        try {
            await Promise.all([this.getColumnas(), this.getUsuario()]);
        } catch (e) {
            console.log(e);
        } finally {
            this.setState({ fetchRegistros: false });
        }
    }

    componentDidMount() {
        document.title = NOMBRE_SIST;
        this.getInicio();
        this.poblarGrilla();
    }

    /* Rutina de filtros avanzados : Seleccion de columnas */
    handleChangefiltroGrid = (filtroGridSelect, actionMeta) => {
        this.setState(
            {
                filtroGridSelect: filtroGridSelect,
                filtroGridRenglon: [],
                filtroGridQuery: "",
                filtroGridQuerySw: ""
            },
            () => {
                if (
                    actionMeta.action === "select-option" &&
                    filtroGridSelect.length > 0
                ) {
                    this.AgregarFiltroGridRenglon();
                    this.setState({
                        filtroGridShow: true,
                        filtroGridCol:
                            filtroGridSelect[filtroGridSelect.length - 1].value,
                        filtroGridLabel:
                            filtroGridSelect[filtroGridSelect.length - 1].label,
                        filtroGridTipo:
                            filtroGridSelect[filtroGridSelect.length - 1].tipo
                    });
                }

                if (actionMeta.action !== "select-option") {
                    this.setState(
                        { registros: this.state.registrosOrig },
                        () => {
                            this.execFiltroGridQuery();
                        }
                    );
                }
            }
        );
    };

    /* Rutina de filtros avanzados : Agregar un renglon de condicion para una columna */
    AgregarFiltroGridRenglon = () => {
        const max = parseInt(this.state.filtroGridRenglon.length) + 1;

        var filtroGridRenglon = this.state.filtroGridRenglon;
        var obj = {};
        obj.renglon = max;
        obj.opera = "===";
        obj.val_col = "V";
        obj.valor_D = "";
        obj.valor_T = "";
        obj.valor_N = "";
        obj.valor_S = "";
        obj.valor_L = [];
        obj.columna = null;
        obj.and_or = "and";

        filtroGridRenglon.push(obj);
        this.setState({ filtroGridRenglon });
    };

    /* Rutina de filtros avanzados : Borrar un renglon de condicion para una columna */
    BorrarFiltroGridRenglon = a_renglon => {
        this.setState({
            filtroGridRenglon: this.state.filtroGridRenglon.filter(ren => {
                return ren.renglon !== a_renglon;
            })
        });
    };

    /* Rutina de filtros avanzados : captura de condiciones del filtro (modal) */
    handleFiltroGridRenglon(event, a_renglon) {
        const { target } = event;
        const value =
            target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });

        var filtroGridRenglon = this.state.filtroGridRenglon;
        const ind = filtroGridRenglon.findIndex(
            ren => Number(ren.renglon) === Number(a_renglon)
        );

        if (name === "opera") {
            filtroGridRenglon[ind].opera = value;
        }
        if (name === "val_col") {
            filtroGridRenglon[ind].val_col = value;
        }
        if (name === "valor_D") {
            filtroGridRenglon[ind].valor_D = value;
        }
        if (name === "valor_T") {
            filtroGridRenglon[ind].valor_T = value;
        }
        if (name === "valor_N") {
            filtroGridRenglon[ind].valor_N = value;
        }
        if (name === "valor_S") {
            filtroGridRenglon[ind].valor_S = value;
        }
        if (name === "valor_L") {
            filtroGridRenglon[ind].valor_L = value;
        }
        if (name === "columna") {
            filtroGridRenglon[ind].columna = value;
        }
        if (name === "and_or") {
            filtroGridRenglon[ind].and_or = value;
        }

        this.setState({ filtroGridRenglon });
    }

    /* Rutina de filtros avanzados : Acepta condicion del filtro para aplicarlo (modal) */
    handlefiltroGridAgregar = () => {
        var filtroGridQuerySw = "";
        var filtroGridQuery = "";
        var opera = "";
        var column = this.state.filtroGridCol;
        var hoy = new Date().toJSON().slice(0, 16).toString(); //.replace('T', ' ')

        if (this.state.filtroGridTipo === "N") {
            column = `Number(fil.${column})`;
        } // si es Numeric , si no lo toma como string y no anda el >
        if (this.state.filtroGridTipo === "S") {
            column = `fil.${column}`;
        } // al string solo le agrego el filtro
        if (this.state.filtroGridTipo === "D") {
            column = `fil.${column}_ffmt`;
        } // si es Date , tomo la columna _ffmt que esta en formato fecha
        if (this.state.filtroGridTipo === "T") {
            column = `fil.${column}_ffmt`;
        } // si es Date , tomo la columna _ffmt que esta en formato fecha

        this.state.filtroGridRenglon.forEach((elem, ind) => {
            // Si no lo completo le pogo valores no nulos
            if (
                this.state.filtroGridTipo === "N" &&
                elem.valor_N.length === 0
            ) {
                elem.valor_N = 0;
            }
            if (
                this.state.filtroGridTipo === "S" &&
                elem.valor_S.length === 0
            ) {
                elem.valor_S = "";
            }
            if (
                this.state.filtroGridTipo === "D" &&
                elem.valor_D.length === 0
            ) {
                elem.valor_D = hoy.slice(0, 10);
            }
            if (
                this.state.filtroGridTipo === "T" &&
                elem.valor_T.length === 0
            ) {
                elem.valor_T = hoy;
            }

            // armo miembro
            if (this.state.filtroGridTipo === "N") {
                opera = `${elem.opera} ${elem.valor_N}`;
            }
            if (this.state.filtroGridTipo === "S") {
                opera = `${elem.opera} '${elem.valor_S}'`;
            }
            if (this.state.filtroGridTipo === "D") {
                opera = `${elem.opera} '${elem.valor_D}'`;
            }
            if (this.state.filtroGridTipo === "T") {
                opera = `${elem.opera} '${elem.valor_T}'`;
            }
            if (this.state.val_col === "C") {
                opera = `${elem.opera} ${elem.columna}`;
            }

            filtroGridQuery = `${filtroGridQuery} ${column} ${opera}`;
            filtroGridQuerySw = `${filtroGridQuerySw} ${this.state.filtroGridLabel} ${opera}`;

            if (ind + 1 < this.state.filtroGridRenglon.length) {
                // si hay otro renglon veo el conector
                filtroGridQuery = `${filtroGridQuery} ${elem.and_or} `;
                filtroGridQuerySw = `${filtroGridQuerySw} ${elem.and_or} `;
            }
        });

        // Guardo las condiciones en el elemto de esa columna
        let filtroGridElem = this.state.filtroGridElem;

        const ind = filtroGridElem.findIndex(
            item => item.value === this.state.filtroGridCol
        );
        filtroGridElem[ind].miembro_in = `(${filtroGridQuery.trim()})`;
        // Para mostrar al usuario convierto signos
        filtroGridElem[ind].miembro_sh = `(${filtroGridQuerySw
            .replaceAll("===", "=")
            .replaceAll("!==", "<>")
            .replaceAll("&&", "AND")
            .replaceAll("||", "OR")
            .trim()})`;
        // muestro la concatenacion
        this.execFiltroGridQuery();

        this.setState({
            filtroGridShow: false
        });
    };

    /* Rutina de filtros avanzados : ejecucion del filtro, una vez aceptado */
    execFiltroGridQuery = () => {
        // recorro filtroGridSelect{} , muestro y ejecuto el query
        let mostrar = "";
        let query = "";
        let registros = this.state.registros;
        this.state.filtroGridSelect.forEach((elem, ind) => {
            mostrar = `${mostrar} ${elem.miembro_sh}`;
            query = `${query} ${elem.miembro_in}`;
            registros = registros.filter(fil =>
                eval(elem.miembro_in.toString())
            );
            //registros = registros.filter(fil => eval(query.toString()) )

            if (ind + 1 < this.state.filtroGridSelect.length) {
                // si hay otro renglon veo el conector
                mostrar = ` ${mostrar} + `;
            }
        });

        this.setState({
            filtroGridQuerySw: mostrar,
            registros: registros
        });
    };

    /*==========================================================================
   Utilidades de filtros y sort para la grilla ppal 
  *==========================================================================*/
    filtrarDatos() {
        const escapedValue = this.state.filterGrilla
            .trim()
            .replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
        const regex = new RegExp(escapedValue, "i");
        return this.state.registros.filter(
            filtro =>
                regex.test(filtro.callid) ||
                regex.test(filtro.origen) ||
                regex.test(filtro.destino) ||
                regex.test(filtro.flujo) ||
                regex.test(filtro.duracion)
        );
    }

    ordenarGrilla = key => {
        const registros = ordenarGrilla(key, this.state.registros);

        this.setState({ registros });
    };

    handleFiltro = () => {
        this.setState(
            {
                showFiltro: !this.state.showFiltro,
                labelFiltro: this.state.showFiltro
                    ? "Más Filtros"
                    : "Menos Filtros"
            },
            () => {
                if (!this.state.showFiltro) {
                    this.setState(
                        {
                            HoraDesde: "00:00",
                            HoraHasta: "23:59",
                            flowValor: "-",
                            statusValor: "-"
                        },
                        this.poblarGrilla
                    );
                }
            }
        );
    };

    /*==========================================================================
   Completo datos de grilla principal 
  *==========================================================================*/
    poblarGrilla = () => {
        if (this.state.desde.length < 8) {
            swal({
                title: `Consulta entre fechas `,
                text: " complete fecha <desde>"
            });
        }
        if (this.state.hasta.length < 8) {
            swal({
                title: `Consulta entre fechas `,
                text: " complete fecha <hasta>"
            });
        }
        if (this.state.hasta < this.state.desde) {
            swal({
                title: `Consulta entre fechas `,
                text: " <hasta> no puede ser menor que <desde>"
            });
        }
        if (this.state.desde.length > 8 && this.state.desde.length > 8) {
            this.setState({ fetchRegistros: true });

            const sql = `${URL_DB}SEL_LLAMADAS_RS('D',${this.state.login_usua_id},'${this.state.desde}','${this.state.hasta}')`;
            axios
                .get(sql)
                .then(response => {
                    this.setState({
                        registros: response.data[0],
                        registrosOrig: response.data[0]
                        // dd_status: [...new Set(response.data[0].map(item => item.call_status))],
                        // dd_flow: [...new Set(response.data[0].map(item => item.call_flow))],
                    });
                })
                .catch(error => console.log(error))
                .finally(() => {
                    this.setState({ fetchRegistros: false });
                });
        }
    };

    /* Opciones del menu, trato hombres, mujeres o todos */
    handleChangeOpc(event) {
        const { target } = event;
        const value =
            target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;

        //this.setState({ [name]: value }, () => {
        this.poblarGrilla();
        //});
    }

    saveFile = (a_url, a_file_name) => {
        fileSaver.saveAs(a_url, a_file_name);
    };

    saveAudios = registros => {
        // fileSaver.saveAs(a_url, a_file_name);

        // console.log("registros", registros);
        if (registros.length === 0) {
            swal({
                title: `Error: `,
                text: " No hay registros para esta operación, corria filtros <Buscar> de nuevo"
            });
        } else {
            this.setState({
                registros,
                showModalDownAudio: true
            });
        }
    };

    saveFileAll = a_registros => {
        let path = "";
        a_registros.forEach((elem, ind) => {
            if (elem.escuchar !== "") {
                path = `O${elem.origen}_D${elem.destino}_H${elem.fecha}.mp3`;
                fileSaver.saveAs(elem.escuchar, path);
            }
        });
        this.setState({
            showModalDownAudio: false
        });
    };

    /*==========================================================================
   RENDER
  *==========================================================================*/

    render() {
        //const respError = this.state.respError;
        const mensajeAlerta = this.state.mensajeAlerta;
        const mensajeColor = this.state.mensajeColor;
        const registrosFiltrados = this.filtrarDatos();

        return (
            <div>
                {mensajeAlerta.length > 1 ? (
                    <Notifications
                        mensaje={mensajeAlerta}
                        mensajeColor={mensajeColor}
                    />
                ) : (
                    ""
                )}

                <Container fluid="true">
                    <Row>
                        <Col md={2} style={{ textAlign: "center" }}>
                            <h2>
                                <b>Llamadas</b>
                            </h2>
                        </Col>
                        <Col md={2}>
                            <Form.Group>
                                <Form.Label>Fecha Desde</Form.Label>
                                <Form.Control
                                    type="datetime-local"
                                    name="desde"
                                    value={this.state.desde}
                                    onChange={e => {
                                        this.setState({
                                            desde: e.target.value
                                        });
                                    }}
                                />
                            </Form.Group>
                        </Col>
                        <Col md={2}>
                            <Form.Group>
                                <Form.Label>Fecha Hasta</Form.Label>
                                <Form.Control
                                    type="datetime-local"
                                    name="hasta"
                                    value={this.state.hasta}
                                    onChange={e => {
                                        this.setState({
                                            hasta: e.target.value
                                        });
                                    }}
                                />
                            </Form.Group>
                        </Col>
                        <Col md={2}>
                            <Form.Group>
                                <Form.Label>Buscar en esta grilla</Form.Label>
                                <FormControl
                                    type="text"
                                    name="buscar"
                                    placeholder="Texto a filtrar"
                                    className="mr-sm-2"
                                    onChange={e => {
                                        this.setState({
                                            filterGrilla: e.target.value
                                        });
                                    }}
                                />
                            </Form.Group>
                        </Col>
                        <Col md={1}>
                            <Button
                                variant="primary"
                                size="sm"
                                onClick={this.poblarGrilla}
                            >
                                <FaSistrix />
                                Buscar
                            </Button>
                        </Col>
                        <Col md={2}>
                            <Button
                                variant="outline-warning"
                                size="sm"
                                onClick={this.handleFiltro}
                            >
                                {this.state.labelFiltro}
                            </Button>
                        </Col>
                        <Col md={1}>
                            <Button
                                variant="outline-success"
                                size="sm"
                                onClick={e =>
                                    exportToCSV(
                                        "Listado llamados",
                                        `Listado_llamados_${NOMBRE_SIST}`,
                                        `${URL_DB}SEL_LLAMADAS_RS('D',${this.state.login_usua_id},'${this.state.desde}','${this.state.hasta}')`
                                    )
                                }
                            >
                                <FaDownload /> Excel
                            </Button>
                            {this.state.usua_nivel === "A" && (
                                <Button
                                    variant="outline-danger"
                                    size="sm"
                                    onClick={e =>
                                        this.saveAudios(registrosFiltrados)
                                    }
                                >
                                    <FaDownload /> Audios
                                </Button>
                            )}
                            {registrosFiltrados.length > 0 &&
                                ` ${registrosFiltrados.length} Regs`}
                        </Col>
                    </Row>

                    {this.state.showFiltro && (
                        <>
                            <Row>
                                <Col md={2}></Col>
                                <Col md={10}>
                                    <InputGroup className="mb-3">
                                        <InputGroup.Text id="basic-addon1">
                                            Filtros:
                                        </InputGroup.Text>
                                        <Select
                                            name="form-field-name"
                                            value={this.state.filtroGridSelect}
                                            onChange={
                                                this.handleChangefiltroGrid
                                            }
                                            placeholder={
                                                "Selecione columnas y condiciones"
                                            }
                                            isMulti
                                            isClearable
                                            options={this.state.filtroGridElem}
                                        />
                                        <InputGroup.Text>
                                            {this.state.filtroGridQuerySw}
                                        </InputGroup.Text>
                                    </InputGroup>
                                </Col>
                            </Row>
                        </>
                    )}

                    <Row>
                        <Col>
                            <Table
                                striped
                                bordered
                                hover
                                size="sm"
                                id="data_table"
                            >
                                <thead className="Grilla-header">
                                    <tr>
                                        <th>
                                            <Button
                                                variant="dark"
                                                size="sm"
                                                onClick={() =>
                                                    this.ordenarGrilla("fecha")
                                                }
                                            >
                                                Fecha-hora
                                            </Button>
                                        </th>
                                        <th>
                                            <Button
                                                variant="dark"
                                                size="sm"
                                                onClick={() =>
                                                    this.ordenarGrilla("origen")
                                                }
                                            >
                                                Origen
                                            </Button>
                                        </th>
                                        <th>
                                            <Button
                                                variant="dark"
                                                size="sm"
                                                onClick={() =>
                                                    this.ordenarGrilla(
                                                        "destino"
                                                    )
                                                }
                                            >
                                                Destino
                                            </Button>
                                        </th>
                                        <th>
                                            <Button
                                                variant="dark"
                                                size="sm"
                                                onClick={() =>
                                                    this.ordenarGrilla("flujo")
                                                }
                                            >
                                                Flujo
                                            </Button>
                                        </th>
                                        <th>
                                            <Button
                                                variant="dark"
                                                size="sm"
                                                onClick={() =>
                                                    this.ordenarGrilla(
                                                        "duracion"
                                                    )
                                                }
                                            >
                                                Duración
                                            </Button>
                                        </th>
                                        <th>
                                            <Button
                                                variant="dark"
                                                size="sm"
                                                onClick={() =>
                                                    this.ordenarGrilla("callid")
                                                }
                                            >
                                                Call#id
                                            </Button>
                                        </th>
                                        <th>Acciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.fetchRegistros && "Cargando..."}
                                    {registrosFiltrados.map((regis, i) => {
                                        return (
                                            <tr key={i}>
                                                <td>{regis.fecha}</td>
                                                <td>{regis.origen}</td>
                                                <td>{regis.destino}</td>
                                                <td
                                                    style={{
                                                        backgroundColor:
                                                            regis.call_flow_color
                                                    }}
                                                >
                                                    {regis.flujo}
                                                </td>
                                                <td>{regis.duracion}</td>
                                                <td>{regis.callid}</td>
                                                <td>
                                                    {regis.escuchar !== "" && (
                                                        <Button
                                                            variant="success"
                                                            size="sm"
                                                            onClick={() =>
                                                                this.setState({
                                                                    fecha: regis.fecha,
                                                                    callid: regis.callid,
                                                                    origen: regis.origen,
                                                                    destino:
                                                                        regis.destino,
                                                                    escuchar:
                                                                        regis.escuchar,
                                                                    showModalPlay: true
                                                                })
                                                            }
                                                        >
                                                            <FaPlay />
                                                        </Button>
                                                    )}
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                                {registrosFiltrados.length > 0 && (
                                    <tfoot className="Grilla-header">
                                        <td
                                            style={{ textAlign: "center" }}
                                        >{`${registrosFiltrados.length} Reg`}</td>
                                        <td colspan={6} />
                                    </tfoot>
                                )}
                            </Table>
                        </Col>
                    </Row>
                </Container>

                <Modal
                    show={this.state.showModalPlay}
                    onHide={() => {
                        this.setState({ showModalPlay: false });
                    }}
                >
                    <Modal.Header
                        closeButton
                        style={{ backgroundColor: "#009900" }}
                    >
                        <Modal.Title>
                            {`Play audio del ${this.state.fecha}`}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Row>
                                <Col md={6}>
                                    <p>{`id#${this.state.callid}`}</p>
                                    <p>{`Origen: ${this.state.origen}`}</p>
                                    <p>{`Destino: ${this.state.destino}`}</p>
                                </Col>
                                <Col md={6}>
                                    {this.state.usua_nivel === "A" && (
                                        <Button
                                            variant="success"
                                            onClick={() => {
                                                this.saveFile(
                                                    this.state.escuchar,
                                                    `O${this.state.origen}_D${this.state.destino}_H${this.state.fecha}.mp3`
                                                );
                                            }}
                                        >
                                            <FaDownload /> Archivo Audio
                                        </Button>
                                    )}
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className="player-wrapper">
                                        <ReactPlayer
                                            width="480px"
                                            height="50px"
                                            playing={true}
                                            controls
                                            url={this.state.escuchar}
                                            config={{
                                                file: {
                                                    attributes: {
                                                        controlsList:
                                                            "nodownload"
                                                    }
                                                }
                                            }}
                                            // disable right click
                                            onContextMenu={e =>
                                                e.preventDefault()
                                            }
                                            onReady={() =>
                                                console.log("Onready")
                                            }
                                            onStart={() =>
                                                console.log("onStart")
                                            }
                                            onPause={() =>
                                                console.log("onPause")
                                            }
                                            onEnded={() =>
                                                console.log("onEnded")
                                            }
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                    </Modal.Body>
                </Modal>

                <Modal
                    show={this.state.filtroGridShow}
                    onHide={() => {
                        this.setState({ filtroGridShow: false });
                    }}
                    dialogClassName="modal-filter"
                >
                    <Modal.Body>
                        <Form>
                            <Row>
                                <b>Agregar condición al filtro:</b>
                            </Row>
                            <Row>
                                <Col>
                                    <thead>
                                        <tr>
                                            <td colSpan={3} />
                                            <td colSpan={2}>
                                                {this.state.filtroGridTipo ===
                                                    "N" && `Tipo dato Numérico`}
                                                {this.state.filtroGridTipo ===
                                                    "S" && `Tipo dato texto`}
                                                {this.state.filtroGridTipo ===
                                                    "D" && `Tipo dato Fecha`}
                                                {this.state.filtroGridTipo ===
                                                    "T" &&
                                                    `Tipo dato Fecha/hora`}
                                                {this.state.filtroGridTipo ===
                                                    "L" && `Seleccione valor`}
                                            </td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.filtroGridRenglon.map(
                                            regis => {
                                                return (
                                                    <tr key={regis.renglon}>
                                                        <td
                                                            style={{
                                                                textAlign:
                                                                    "left"
                                                            }}
                                                        >
                                                            <Form.Control
                                                                type="text"
                                                                name="col"
                                                                value={
                                                                    this.state
                                                                        .filtroGridLabel
                                                                }
                                                                readOnly
                                                                style={{
                                                                    fontWeight:
                                                                        "bold",
                                                                    textAlign:
                                                                        "right"
                                                                }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <select
                                                                className="form-control"
                                                                name="opera"
                                                                value={
                                                                    regis.opera
                                                                }
                                                                onChange={e => {
                                                                    this.handleFiltroGridRenglon(
                                                                        e,
                                                                        regis.renglon
                                                                    );
                                                                }}
                                                            >
                                                                <option
                                                                    key={1}
                                                                    value={
                                                                        "==="
                                                                    }
                                                                >
                                                                    {" "}
                                                                    {`=`}{" "}
                                                                </option>
                                                                <option
                                                                    key={2}
                                                                    value={">"}
                                                                >
                                                                    {" "}
                                                                    {`>`}{" "}
                                                                </option>
                                                                <option
                                                                    key={3}
                                                                    value={">="}
                                                                >
                                                                    {`>=`}{" "}
                                                                </option>
                                                                <option
                                                                    key={4}
                                                                    value={"<"}
                                                                >
                                                                    {`<`}{" "}
                                                                </option>
                                                                <option
                                                                    key={5}
                                                                    value={"<="}
                                                                >
                                                                    {" "}
                                                                    {`<=`}{" "}
                                                                </option>
                                                                <option
                                                                    key={6}
                                                                    value={
                                                                        "!=="
                                                                    }
                                                                >
                                                                    {`<>`}{" "}
                                                                </option>
                                                            </select>
                                                        </td>
                                                        <td
                                                            style={{
                                                                width: "70px"
                                                            }}
                                                        >
                                                            <select
                                                                className="form-control"
                                                                name="val_col"
                                                                value={
                                                                    regis.val_col
                                                                }
                                                                onChange={e => {
                                                                    this.handleFiltroGridRenglon(
                                                                        e,
                                                                        regis.renglon
                                                                    );
                                                                }}
                                                            >
                                                                <option
                                                                    key={1}
                                                                    value={"V"}
                                                                >
                                                                    {" "}
                                                                    {`Valor`}{" "}
                                                                </option>
                                                                <option
                                                                    key={2}
                                                                    value={"C"}
                                                                >
                                                                    {" "}
                                                                    {`Colum`}{" "}
                                                                </option>
                                                            </select>
                                                        </td>
                                                        <td>
                                                            {this.state
                                                                .filtroGridTipo ===
                                                                "N" &&
                                                                regis.val_col ===
                                                                    "V" && (
                                                                    <Form.Control
                                                                        type="number"
                                                                        name="valor_N"
                                                                        onChange={e => {
                                                                            this.handleFiltroGridRenglon(
                                                                                e,
                                                                                regis.renglon
                                                                            );
                                                                        }}
                                                                    />
                                                                )}
                                                            {this.state
                                                                .filtroGridTipo ===
                                                                "S" &&
                                                                regis.val_col ===
                                                                    "V" && (
                                                                    <Form.Control
                                                                        type="text"
                                                                        name="valor_S"
                                                                        onChange={e => {
                                                                            this.handleFiltroGridRenglon(
                                                                                e,
                                                                                regis.renglon
                                                                            );
                                                                        }}
                                                                    />
                                                                )}
                                                            {this.state
                                                                .filtroGridTipo ===
                                                                "D" &&
                                                                regis.val_col ===
                                                                    "V" && (
                                                                    <Form.Control
                                                                        type="date"
                                                                        name="valor_D"
                                                                        onChange={e => {
                                                                            this.handleFiltroGridRenglon(
                                                                                e,
                                                                                regis.renglon
                                                                            );
                                                                        }}
                                                                    />
                                                                )}
                                                            {this.state
                                                                .filtroGridTipo ===
                                                                "T" &&
                                                                regis.val_col ===
                                                                    "V" && (
                                                                    <Form.Control
                                                                        type="datetime-local"
                                                                        name="valor_T"
                                                                        onChange={e => {
                                                                            this.handleFiltroGridRenglon(
                                                                                e,
                                                                                regis.renglon
                                                                            );
                                                                        }}
                                                                    />
                                                                )}
                                                            {regis.val_col ===
                                                                "C" && (
                                                                <select
                                                                    className="form-control"
                                                                    name="columna"
                                                                    value={
                                                                        regis.columna
                                                                    }
                                                                    onChange={e => {
                                                                        this.handleFiltroGridRenglon(
                                                                            e,
                                                                            regis.renglon
                                                                        );
                                                                    }}
                                                                >
                                                                    {this.state.filtroGridElem.map(
                                                                        col => {
                                                                            return (
                                                                                <option
                                                                                    key={
                                                                                        col.value
                                                                                    }
                                                                                    value={
                                                                                        col.value
                                                                                    }
                                                                                >
                                                                                    {" "}
                                                                                    {
                                                                                        col.label
                                                                                    }
                                                                                </option>
                                                                            );
                                                                        }
                                                                    )}
                                                                </select>
                                                            )}
                                                        </td>
                                                        <td
                                                            style={{
                                                                width: "70px"
                                                            }}
                                                        >
                                                            <select
                                                                className="form-control"
                                                                name="and_or"
                                                                value={
                                                                    regis.and_or
                                                                }
                                                                onChange={e => {
                                                                    this.handleFiltroGridRenglon(
                                                                        e,
                                                                        regis.renglon
                                                                    );
                                                                }}
                                                            >
                                                                <option
                                                                    key={1}
                                                                    value={"&&"}
                                                                >
                                                                    {" "}
                                                                    {`AND`}{" "}
                                                                </option>
                                                                <option
                                                                    key={2}
                                                                    value={"||"}
                                                                >
                                                                    {" "}
                                                                    {`OR`}{" "}
                                                                </option>
                                                            </select>
                                                        </td>
                                                        <td>
                                                            {regis.renglon >
                                                                1 && (
                                                                <Button
                                                                    variant="danger"
                                                                    size="sm"
                                                                    onClick={() =>
                                                                        this.BorrarFiltroGridRenglon(
                                                                            regis.renglon
                                                                        )
                                                                    }
                                                                >
                                                                    <FaTrashAlt />
                                                                </Button>
                                                            )}
                                                        </td>
                                                    </tr>
                                                );
                                            }
                                        )}
                                    </tbody>
                                </Col>
                            </Row>
                            <br />
                            <Row>
                                <Col xs={6} style={{ textAlign: "left" }}>
                                    <Button
                                        variant="primary"
                                        size="sm"
                                        onClick={this.AgregarFiltroGridRenglon}
                                    >
                                        Otra condicion mas ...
                                    </Button>
                                </Col>
                                <Col xs={6} style={{ textAlign: "right" }}>
                                    <Button
                                        variant="primary"
                                        size="sm"
                                        onClick={this.handlefiltroGridAgregar}
                                    >
                                        Listo !
                                    </Button>
                                    <Button
                                        variant="secondary"
                                        size="sm"
                                        onClick={() => {
                                            this.setState({
                                                filtroGridShow: false
                                            });
                                        }}
                                    >
                                        Cancelar
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Modal.Body>
                </Modal>

                <Modal
                    show={this.state.showModalDownAudio}
                    onHide={() => {
                        this.setState({ showModalDownAudio: false });
                    }}
                >
                    <Modal.Header
                        closeButton
                        style={{ backgroundColor: "#ff6666" }}
                    >
                        <Modal.Title>{`Bajada todos los audios filtrados`}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Row>
                                <Col md={6}>
                                    <p>{`Cantidad de archivos = ${this.state.registros.length}`}</p>
                                </Col>
                                <Col md={6}>
                                    {this.state.usua_nivel === "A" && (
                                        <Button
                                            variant="success"
                                            onClick={() => {
                                                this.saveFileAll(
                                                    this.state.registros
                                                );
                                            }}
                                        >
                                            <FaDownload /> Archivos Audio
                                        </Button>
                                    )}
                                </Col>
                            </Row>
                        </Form>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

export default LlamadasPage_v2;
