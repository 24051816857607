import React from 'react';
import { NOMBRE_SIST } from './../../constants';
import { Navbar, Nav, Dropdown } from 'react-bootstrap';
import { FaUserAlt } from 'react-icons/fa';

const Header = () => (
  <Navbar bg="dark" variant="dark" expand="lg" sticky="top">
    <Navbar.Brand href="#home">{ NOMBRE_SIST }</Navbar.Brand>
    <Navbar.Toggle aria-controls="basic-navbar-nav" />
    <Navbar.Collapse id="basic-navbar-nav">
      <Nav className="mr-auto">
        <Nav.Link href="llamadas_v2">Llamadas</Nav.Link>
        <Nav.Link href="#"></Nav.Link>
      </Nav>

      <Dropdown /*className="btn-group dropleft"*/>
        <Dropdown.Toggle variant="success" id="dropdown-basic" >
        <i className="fas fa-user"></i> {sessionStorage.getItem('NOMBRE_USUARIO')}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item href="/UsuarioLogueado">Datos Usuario</Dropdown.Item> 
          <Dropdown.Divider /> 
          <Dropdown.Item onClick={() => { window.location = '/login'; 
                                          sessionStorage.setItem('USUARIO',0);
                                          sessionStorage.setItem('NOMBRE_USUARIO','');
                                  }}><FaUserAlt /> Cerrar Sesión</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>

    </Navbar.Collapse>
  </Navbar>
);

export default Header;

