//import logo from './logo.svg';
import './App.css';

import React from 'react';
import './assets/styles/index.scss'; // CSS IMPORTS
import { Redirect, Switch, Route, BrowserRouter as Router } from 'react-router-dom';

import Header from './components/Header';

//import Home from './pages/home'
import LlamadasPage_v2 from './pages/llamadas_v2';
import DatosUsuario from './components/UsuarioLogueado';
import Login from './components/Login';


class App extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      login_usua_id: sessionStorage.getItem('USUARIO'),
      };  

  }

  componentDidMount() {

    if (sessionStorage.getItem('USUARIO')===0) {
      sessionStorage.setItem('NOMBRE_USUARIO','Usuario Log')
    }
    
  }

  render() {

    return (

      <div>
      <Router>
        {/* HEADER */}
        <Header a_usu_login={'cocota'} />
        {/* BODY DINAMIC CONTENT */}
        <Switch>
          <Route exact path="/llamadas_v2" component={LlamadasPage_v2} />
          <Route exact path="/UsuarioLogueado" component={DatosUsuario} /> 
          <Route path="/login" component={Login} />
{/*          <Route path="/datos-usuario" component={DatosUsuario} />*/}
{/*          <Route path="*" component={Home} /> */}
        </Switch>

        { Number(this.state.login_usua_id)===0 &&
                         <Redirect to={'/login'} />   }

        {/* FOOTER */}
      </Router>

      </div>

    );
  }
}

export default App;



